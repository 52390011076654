import React from "react";
import styled from "styled-components";
import Card from "../Card/Card";
import ProductArch from "../../Assets/SecondPage/Group 27.svg";
import GooglePlay from "../../Assets/SecondPage/Group 30.svg";
import WebDevlopment from "../../Assets/SecondPage/Group 28.svg";
import DigitalMarket from "../../Assets/SecondPage/Group 29.svg";

export const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 0 0 0;
  gap: 1rem;
`;
export const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
   gap: 1rem;
`;

export const CardWrapperMain = styled.div`
  display: flex;
  padding: 2rem 0rem;
  gap: 1rem;
  justifyContent: center;
    @media (max-width: 992px) {
      padding: 0rem 1.5rem 2rem 3rem;
  }
  @media (max-width: 768px) {
    flex-direction: column;
}
`;


const CardWrapper = () => {
  return (
    <>
      <CardWrapperMain>
        <LeftColumn>
          <Card
            padding="0"
            icon={ProductArch}
            iconSize="small"
            title="Product architecture"
            subtitle="Design best fit architecture for the application and large scale software to provide best output."
          />
          <Card
            padding="0"
            icon={WebDevlopment}
            iconSize="small"
            title="Web Development"
            subtitle="Best website and web application with latest 
          web framework solutions"
          />
        </LeftColumn>
        <RightColumn>
          <Card
            padding="0"
            icon={GooglePlay}
            iconSize="small"
            title="Apps Development"
            subtitle="Develop application for 
          app Store and play Store
          to grow your business.
          "
          />
          <Card
            padding="0"
            icon={DigitalMarket}
            iconSize="small"
            title="Digital Marketing"
            subtitle="Grow your product sales with social media marketing, SEO, and latest marketing strategies.     "
          />
        </RightColumn>
      </CardWrapperMain>
    </>
  );
};

export default CardWrapper;
