import styled from "styled-components";
import React, { useState, useEffect } from "react";
import { FileUploader } from "react-drag-drop-files";
import DragFile from "../DragFile/DragFile";
import ResumeSingle from "../../Assets/JoinUsPage/ResumeSingle.svg";
import Modal from 'react-modal';
import ResumePreviewModel from "../../Components/ResumePreviewModel/ResumePreviewModel"
import {getDownloadURL, listAll, ref, uploadBytes } from "firebase/storage";
import { v4 } from "uuid";
import { fileDB,db } from "../../Firebase/config";
import { collection, addDoc ,getDocs } from "firebase/firestore"; 
import ReCAPTCHA from "react-google-recaptcha";
import { pdfjs } from 'react-pdf'
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;




export const JoinUsFormMain = styled.div`
    box-sizing: border-box; 
    padding: 40px;
    width: 95%;
    border-radius: 20px;
    border: 2px solid #C7CCFF;
    background: linear-gradient(49deg, rgba(217, 217, 217, 0.10) -8.06%, rgba(217, 217, 217, 0.00) 132.12%);
    backdrop-filter: blur(50px);
    display: flex;
    flex-direction: column;
    gap: 44px;
    position: relative;
    @media screen {
        @media (max-width: 768px) {
            padding: 20px;
        }
    }
`;
export const FormFields = styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;
`;
export const Heading = styled.div`
  color: white;
  h3 {
   
    font-family: Poppins;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0em;
  }
`;
export const SingleField = styled.div`
    display: flex;
    flex-direction: column;
    gap: 7px;
`;
export const Formlabel = styled.div`
    color: #FFF;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;
export const InputField = styled.input`
    width: 100%;
    box-sizing: border-box;
    padding:15px 20px;
    border-radius: 5px;
    border: 2px solid #C7CCFF;
    background: linear-gradient(49deg, rgba(217, 217, 217, 0.10) -8.06%, rgba(217, 217, 217, 0.00) 132.12%);
    color: #ffffff;
    ::placeholder {
        color: rgba(255, 255, 255, 0.35);
      }
    :focus-visible {
        outline: none;
    }
    :autofill {
        border: 2px solid #C7CCFF;
        background: linear-gradient(49deg, rgba(217, 217, 217, 0.10) -8.06%, rgba(217, 217, 217, 0.00) 132.12%) ;
        -webkit-backdrop-filter: blur(50px);
        backdrop-filter: blur(50px);
        -webkit-background-clip: text;
        -webkit-text-fill-color: #ffffff;
        transition: background 5000s ease-in-out 0s;
        :focus{
            border: 2px solid #C7CCFF;
            background: linear-gradient(49deg, rgba(217, 217, 217, 0.10) -8.06%, rgba(217, 217, 217, 0.00) 132.12%) ;
            -webkit-backdrop-filter: blur(50px);
            backdrop-filter: blur(50px);
            -webkit-background-clip: text;
            -webkit-text-fill-color: #ffffff;
            transition: background 5000s ease-in-out 0s;
        }
       
    }
`;
export const SubmitBtn = styled.button`
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 18px;
    line-height: normal;
    border: 0px;
    width: 100%;
    padding: 16px 10px;
    border-radius: 10px;
    background: linear-gradient(88deg, #00A6FE 0.58%, #683BFA 25.95%, #E002FB 49.25%, #F31C57 75.14%, #F65F27 100%);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
`;
export const AvatarPosition = styled.div`
    position: absolute;
    bottom: 0;
    left: 95%;
    @media screen {
        @media (max-width: 992px) {
        
        }
        @media (max-width: 768px) {
            left: 89%;
        }
    }
`;
export const ImgWrapper = styled.img`
   width: fit-content;
   height: 100%;
   object-fit: contain;
   max-height: 400px;
   @media screen {
    @media (max-width: 992px) {
    
    }
    @media (max-width: 768px) {
        max-height: 200px;
    }
  }
`;
export const DragFileSuccess = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px 20px;
    border-radius: 5px;
    border: 2px solid #C7CCFF;
    background: linear-gradient(49deg, rgba(217, 217, 217, 0.10) -8.06%, rgba(217, 217, 217, 0.00) 132.12%);
    gap: 15px;
    position: static;
    z-index: 99999;
`;
export const DragFileSuccessInner = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;
export const CloseBtn = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    border: 1px solid #C7CCFF;
    padding: 5px;
    border-radius: 100%;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    position: relative;
    z-index: 222;
`;
export const SuccessDragFileIcon = styled.img`
   position: relative;
   z-index: 22;
`;
export const DragFiletext = styled.span`
    color: #FFF;
    font-size: 14px;
    line-height: normal;
`;
export const SpanText = styled.span`
    background: linear-gradient(270deg, #06A0FE -1.08%, #5C49FB 7.99%, #BD14FB 15.29%, #F4284F 23.47%, #F6572D 30.55%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
`;
export const ErrorMsg = styled.p`
    color: red;
    font-size: 14px;
    font-weight: 500;
`;
export const JobSelect = styled.div`
    width: 100%;
    box-sizing: border-box;
    padding:15px 20px;
    border-radius: 5px;
    border: 2px solid #C7CCFF;
    background: linear-gradient(49deg, rgba(217, 217, 217, 0.10) -8.06%, rgba(217, 217, 217, 0.00) 132.12%);
    color: #ffffff;
    ::placeholder {
        color: rgba(255, 255, 255, 0.35);
      }
`;
export const JobOption = styled.option`
    width: 100%;
    box-sizing: border-box;
    padding:15px 20px;
    border-radius: 5px;
    border: 2px solid #C7CCFF;
    background: linear-gradient(49deg, rgba(217, 217, 217, 0.10) -8.06%, rgba(217, 217, 217, 0.00) 132.12%);
    color: #000;
    height: 50px;
    ::placeholder {
        color: rgba(255, 255, 255, 0.35);
      }
`;
export const SelectPost = styled.select`
    width: 100%;
    background: transparent;
    border: 0px;
    color: rgb(255, 255, 255);
    :focus-visible {
        outline: none;
    }
`;

export const CapchaInput = styled.div`
    display: flex;
    gap: 10px;
`;
export const Captchasingle = styled.div`
    flex: 0 0 50%;
`

const fileTypes = ["PDF"];
const customStyles = {  
    overlay: {
        background: "black",
    },
    content: {
    zIndex: '9999999',
    width: "fitContent",
    background: "#000000",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "80px",
    height: "100%",
    // margin: "80px 50px",
    },
  };
  
const JoinUsFrom = (props) => {

    const [file, setFile] = useState(null);
    const [storeValues, setStoreValues] = useState([]);
    const [captcha, setCaptcha] = useState(false);
    const [userData, setUserData] = useState({
        fullName: "",
        mobileNo: "",
        email: "",
        jobValue: "",
    });
    const  [errors, setErrors] = useState({})
    const ValidationErrors ={}
    const grecaptchaObject = window.grecaptcha
    const recaptchaRef = React.createRef();
    let ResumeUrl ='';
    useEffect(() => {
        fetchPostData();
      }, []);
      const fetchPostData = async () => {
        const querySnapshot = await getDocs(collection(db,"JobPosts"));
        const tempArray =[];
        querySnapshot.forEach((doc) =>{
            tempArray.push(doc.data());
        });
        setStoreValues(tempArray);
    }

    const handleChange = (file) => {
        Validation();
      setFile(file);
    };

    const closeupload = (event) =>{
        event.preventDefault()
        setFile(null);
    }
    const [modalIsOpen, setIsOpen] = React.useState(false);
    function openModal() {
        setIsOpen(true);
    }
    function closeModal() {
        setIsOpen(false);
    }
    let name,value;
    const postUserData = (event) =>{
        Validation();
        name = event.target.name;
        value = event.target.value;
        
        setUserData({...userData, [name]: value });
    }
    const onChange = (value) =>{
        Validation();
        setCaptcha(true);
    }
    const handleUpload = async (event) =>{
        event.preventDefault();
        const {fullName , mobileNo, email,jobValue} = userData;
        Validation();
        if(Object.keys(ValidationErrors).length === 0 &&  captcha == true){
            try {
                const fileRef = ref(fileDB, `Resume/${v4()}`)
                await uploadBytes(fileRef,file).then( (snapshot) => {
                getDownloadURL(snapshot.ref).then( async (url) =>{
                    ResumeUrl = url
                        const docRef = await addDoc(collection(db, "Applications"), {
                        Name: fullName,
                        mobile: mobileNo,
                        email: email,
                        post: jobValue,
                        ResumeUrl: ResumeUrl,
                        });
                        if(docRef){
                            
                            setUserData({
                                fullName : "", 
                                mobileNo: "", 
                                email: "", 
                                jobValue: "",
                            })
                            ResumeUrl = "";
                            setFile(null);
                            grecaptchaObject.reset()
                            alert("Application applied successfully.") 
                        }
                        else{
                            alert("Something wrong")
                        }
                    })  
                } 
                
            );
            } catch (e) {
                alert("Application not submitted.")
            }
        };  
    }

    const Validation = () =>{
        
        if(!userData.fullName) {
            ValidationErrors.fullName = " fullname is require"
        }
        else if(!userData.mobileNo) {
            ValidationErrors.mobile = " Mobile No. is require"
        }
        else if(!(userData.mobileNo.match('[0-9]{9}'))){
            ValidationErrors.mobile = " Enter valid Mobile No."
        }
        else if(!userData.email) {
            ValidationErrors.email = " Email is require"
        }
        else if(!(/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(userData.email))){
            ValidationErrors.email = " Enter valid Email"
        }
        else if( !userData.jobValue){
            ValidationErrors.post = " Post is require"
        }
        else if(!file) {
            ValidationErrors.file = " file is require"
        }
        else if(!captcha){
            ValidationErrors.capcha = " check the Capcha"
        }
        setErrors(ValidationErrors)
    }
    return(
        <>
            <JoinUsFormMain>
                <form style={{display: "flex",flexDirection: "column",gap: "30px",}}>
                    <FormFields>
                        <Heading>
                            <h3>{props.Heading}</h3>
                        </Heading>
                        <SingleField>
                            <Formlabel>{props.Label1}</Formlabel>
                            <InputField 
                                name="fullName"
                                type="text" 
                                placeholder="Nisarg Tailor"
                                value={userData.fullName} 
                                onChange={postUserData}  
                                autoComplete='off'  
                            >   
                            </InputField>
                            {errors.fullName && <ErrorMsg>{errors.fullName}</ErrorMsg>} 
                           
                        </SingleField>
                        <SingleField>
                            <Formlabel>{props.Label2}</Formlabel>
                            <InputField
                                name="mobileNo"
                                type="tel" 
                                placeholder="+91 8733896867"
                                value={userData.mobileNo} 
                                onChange={postUserData}   
                                maxLength="10"
                                autoComplete='off' 
                            >
                            </InputField>
                            {errors.mobile && <ErrorMsg>{errors.mobile}</ErrorMsg>} 
                        </SingleField>
                        <SingleField>
                            <Formlabel>{props.Label3}</Formlabel>
                            <InputField 
                                name="email"
                                type="email" 
                                placeholder="example@devetree.com"
                                value={userData.email} 
                                 onChange={postUserData}  
                                 autoComplete='off' 
                                ></InputField>
                                 {errors.email && <ErrorMsg>{errors.email}</ErrorMsg>} 
                        </SingleField>
                            
                        <SingleField>
                        <Formlabel>{props.Label5}</Formlabel>
                            <JobSelect>
                                <SelectPost
                                    name="jobValue"
                                    id="job"
                                    value={userData.jobValue}
                                    placeholder="Select Post"
                                    onChange={postUserData}
                                >
                                    <JobOption value={""}>Select post</JobOption>
                                    {storeValues.map((item) => (
                                        <JobOption value={item.jobpost}>{item.jobpost}</JobOption>
                                    ))}
                                </SelectPost>
                            </JobSelect> 
                            {errors.post && <ErrorMsg>{errors.post}</ErrorMsg>} 
                        </SingleField>
                    </FormFields> 
                    {
                        file ?
                            <DragFileSuccess>
                                <DragFileSuccessInner onClick={openModal}>
                                    <SuccessDragFileIcon 
                                        src={ResumeSingle}
                                        alt="drop file icon"
                                    />
                                    <DragFiletext>File Uploaded <SpanText>Successfully !</SpanText> </DragFiletext>
                                </DragFileSuccessInner>
                                <CloseBtn onClick={closeupload}>X</CloseBtn>
                            </DragFileSuccess>
                    :
                    <FileUploader children={<DragFile closeupload={closeupload} file={file}  Image4={props.Image4}  Image3={props.Image3}/>}  handleChange={handleChange} name="file" types={fileTypes} />
                   
                   }   
                   {errors.file && <ErrorMsg>{errors.file }</ErrorMsg>} 
                    <Modal
                        isOpen={file && modalIsOpen}
                        onRequestClose={closeModal}
                        style={customStyles}
                        contentLabel="Example Modal"
                        children={<ResumePreviewModel closeModal={closeModal}  file={file}  />}
                    >
                    </Modal>
                        <SingleField>
                            <ReCAPTCHA
                                ref={recaptchaRef}  
                                sitekey="6LcF50kpAAAAAKX_vj1-tIX3JLDVaRWcQXDuNugd"
                                theme= "dark"
                                grecaptcha={grecaptchaObject}
                                onChange={onChange}
                            />
                             {errors.capcha && <ErrorMsg>{errors.capcha}</ErrorMsg>} 
                        </SingleField>
                    <SubmitBtn type="button" value="SUBMIT" onClick={handleUpload} >SUBMIT</SubmitBtn>
                </form>
                <AvatarPosition>
                    <ImgWrapper src={props.Image2} alt="avatar image 2" />
                </AvatarPosition>
            </JoinUsFormMain>
        </>
    )
}

export default JoinUsFrom;