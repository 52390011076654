import React from "react";
import { Contain, Journey, Line } from "./HomeStyle";

const Home = () => {
  return (
    <>
      {/* <Navbar /> */}
      <Contain>
        <div 
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
            <h1>DEVETREE</h1>
            <p>The team of creative and passionate designers and developers</p>
        </div>
        <Journey>
          <h3>Let's start the journey</h3>
        </Journey>

        {/* <div>
          <Line></Line>
        </div> */}
      </Contain>
      <div
        style={{
          padding: "0 100px",
        }}
      >
        <div
          style={{
            width:"50%",
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
          }}
        >
          <Line
            // style={{
            //   height: "200px",
            //   width: "5px",
            //   background: "linear-gradient(180deg, #2E2AA5 0%, #2400FF 100%)",
            // }}
          ></Line>
        </div>
      </div>
    </>
  );
};

export default Home;
