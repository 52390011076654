import React from "react";
import Carosule from "../../Components/Carosule/Carosule";
import styled from "styled-components";
import { HorizantalLine, VerticalLine } from "../../Components/Lines/Line";
import {TechnologiesData} from "./Data"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  position: relative;
  ::after {
    content: "";
    position: absolute;
    width: 40%;
    height: 40%;
    background-size: contain;
    background-position: left;
    background: radial-gradient(
        74.36% 74.36% at 50% 50%,
        rgba(1, 23, 222, 0.4) 0%,
        rgba(3, 0, 153, 0) 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    filter: blur(100px);
    @media screen {
      @media (max-width: 992px) {
        height: 20%;
      }
    }
  }
`;
const Line = styled.div`
  height: 100%;
  display: block;
  @media screen {
    @media (max-width: 992px) {
      display: none;
    }
  }
`;
const TechnologyTitle = styled.h1`
    -webkit-text-stroke: 2px rgba(255, 255, 255, 0.2);
    font-family: Poppins;
    font-size: 70px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
`;
const CarosuleWrapper = styled.div`
  padding: 2rem 1rem;
`;

const Sixpage = () => {
  return (
    <>
      <HorizantalLine
        length="full"
        color="linear-gradient(180deg, #00FC65 0%, #00FC65 100%)"
      />
      <Container>
      
            <Line>
              <div
                style={{
                  padding: "0 5rem",
                }} 
              >
                <div
                  style={{
                    height: "200px",
                    width:" 50%",
                  }} 
                >
                  <VerticalLine color="linear-gradient(180deg, #00FC65 0%, #7000FF 34.9%, #CD0C86 77.6%)" />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  zIndex: "-99",
                  top: "0",
                  left: "0",
                  paddingTop: "100px",
                }}
              >
                <TechnologyTitle>Technology</TechnologyTitle>
              </div>
            </Line>
        <CarosuleWrapper>
          <Carosule CaurasolData={TechnologiesData} />
        </CarosuleWrapper>
        <Line>
          {/* <VerticalLine color=" linear-gradient(180deg, #D60080 0%, #00FFFF 100%)" /> */}
        </Line>
      </Container>
    </>
  );
};

export default Sixpage;
