import React from "react";
import styled from "styled-components";
import { HorizantalLine, VerticalLine } from "../Lines/Line";


const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
`;
const Line3 = styled.div`
  height: 100%;
  display: block;
  @media screen {
    @media (max-width: 992px) {
      display: none;
    }
  }
`;

const LinesFullSection = (props) => {
    return(
        <>
            <Container>
                <HorizantalLine
                    length={props.length}
                    color={props.horizontallinecolor}
                />
                <Line3>
                    <div
                        style={{
                        padding: "0 5rem",
                        }} 
                    >
                        <div
                        style={{
                            height: "100px",
                            width:" 50%",
                        }} 
                        >
                            <VerticalLine color={props.verticallinecolor}/>
                        </div>
                    </div>
                </Line3>
            </Container>
        </>
    )
}

export default LinesFullSection;