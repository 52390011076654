import React from "react";
import styled from "styled-components";
import LinkedIn from "../../Assets/Icons/LinkedIn 2.svg";
import Instagram from "../../Assets/Icons/Instagram.svg";
import Googlemap from "../../Assets/Icons/Google Maps Old.svg";
import Github from "../../Assets/Icons/GitHub.svg";
import back from "../../Assets/Group 85.svg";
import Email from "../../Assets/Icons/Secured Letter.svg";
import Phone from "../../Assets/Icons/Phone.svg";
import Location from "../../Assets/Icons/Location.svg";

export const Container = styled.div`
    width: 100%;
    height: 100%;
    background-color: #050216;
    background-image: url("${back}");
    background-repeat: no-repeat;
    background-position: left;
    background-size: 100% 107%;
    @media screen {
        @media (max-width: 992px) {
            padding: 80px 100px 40px 100px;
        }
        @media (max-width: 768px) {
            padding: 0px 20px 40px 20px;
        }
    }
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  background: linear-gradient(
    49.25deg,
    rgba(217, 217, 217, 0.1) -8.06%,
    rgba(217, 217, 217, 0) 132.12%
  );
  backdrop-filter: blur(50px);

  /* Note: backdrop-filter has minimal browser support */

  border-radius: 20px;
  border: 5px solid rgba(217, 217, 217, 0.1);
  padding: 2rem;
  @media screen {
    @media (max-width: 768px) {
      flex-direction: column;
      padding: 1rem;
    //   margin: 1rem;
    }
  }
`;
const Left = styled.div`
    padding: 0rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:30px;
    h2 {
    margin: 0;
    font-family: "Poppins", sans-serif;

    font-weight: 800;
    line-height: 1.2;

    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    background-size: 200%;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #fff;
    text-fill-color: transparent;
    fill: none;
    display: flex;
    align-items: center;
    @media screen and (max-width: 992px) {
        font-size: 68px;
        line-height: 1.2;
        -webkit-text-stroke-width: 2px;
      }
      @media screen and (max-width: 768px) {
        font-size: 25px;
        line-height: 1.2;
        -webkit-text-stroke-width: 1px;
      }
  }
  @media screen and (max-width: 768px) {
    padding: 0rem;
  }
`;
const ItemWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    width: 100%;
  @media screen {
    @media screen and (max-width: 992px) {
        grid-template-columns: 1fr;
      }
    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;
export const IconWrapper = styled.div`
  padding: 0rem 0.5rem 0rem 0rem;
`;
export const ContactDetails = styled.div`
  display: flex;
  flex-direction: row;
  
  a {
    text-decoration: none;
    color: #fff;
  }
`;

export const SocialIcons = styled.div`
  padding: 1rem 0rem;
  display: flex;
  flex-direction: row;
  
`;
export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  background: linear-gradient(
    25.52deg,
    rgba(27, 0, 105, 0.2) -7.65%,
    rgba(27, 0, 105, 0) 104.52%
  );
  box-shadow: inset 0px 0px 27px rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(2px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 10px;
`;


const JoinUsContact = () =>{
    return (
        <Container>
            <Wrapper>
                <Left>
                    <div style={{display: "flex", width: "100%",}}>
                        <h2>Let's Connect</h2>
                    </div>
                    
                    <ItemWrapper>
                        {/* <div style={{ display:'flex', justifyContent: "space-between", gap:"10px",}}> */}
                            <ContactDetails>
                                <IconWrapper>
                                    <Icon>
                                        <img src={Email} alt="email" />
                                    </Icon>
                                </IconWrapper>
                                <a href="mailto:Support@devetree.com" target="_blank">Support@devetree.com</a>
                            </ContactDetails>
                            <ContactDetails>
                                <IconWrapper>
                                    <Icon>
                                        <img src={Phone} alt="Phone" />
                                    </Icon>
                                </IconWrapper>
                                <a href="tel:+918733896867" target="_blank">+918733896867</a>
                            </ContactDetails>
                            <ContactDetails>
                                <IconWrapper>
                                    <Icon>
                                        <img src={Location} alt="Location" />
                                    </Icon>
                                </IconWrapper>
                                <a href="https://goo.gl/maps/PaxtPWho8oMecwrbA" target="_blank">
                                    302, MARVELLA SHOPPERS, Lal Bahadur Shastri Rd, Bardoli,
                                    Gujarat 394601
                                </a>
                            </ContactDetails>
                        {/* </div> */}
                        <SocialIcons>
                            <IconWrapper>
                                <Icon>
                                <a style={{display: 'flex', alignItems: 'center',}} href="https://in.linkedin.com/company/devetree-tachnolabs" target="_blank" ><img src={LinkedIn} alt="LinkedIn" /> </a>
                                </Icon>
                            </IconWrapper>

                            <IconWrapper>
                                <Icon>
                                <a style={{display: 'flex', alignItems: 'center',}} href="https://instagram.com/devetreetechnolab?igshid=MzMyNGUyNmU2YQ==" target="_blank" > <img src={Instagram} alt="insta" /> </a>
                                </Icon>
                            </IconWrapper>

                            <IconWrapper>
                                <Icon>
                                <a style={{display: 'flex', alignItems: 'center',}} href=" https://goo.gl/maps/PaxtPWho8oMecwrbA" target="_blank" ><img src={Googlemap} alt="Googlemap" /> </a>
                                </Icon>
                            </IconWrapper>
                            <IconWrapper>
                                <Icon>
                                <a style={{display: 'flex', alignItems: 'center',}} href="https://github.com/DeveTreea" target="_blank" > <img src={Github} alt="Github" /> </a>
                                </Icon>
                            </IconWrapper>
                        </SocialIcons>
                    </ItemWrapper>
                </Left>
            </Wrapper>
        </Container>
    )
}

export default JoinUsContact;