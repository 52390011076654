import styled from "styled-components";

export const DragFileMain = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 25px 20px;
    border-radius: 5px;
    border: 2px solid #C7CCFF;
    background: linear-gradient(49deg, rgba(217, 217, 217, 0.10) -8.06%, rgba(217, 217, 217, 0.00) 132.12%);
`;

export const DragFileIcon = styled.img`
   
`;

export const DragFiletext = styled.span`
    color: #FFF;
    font-size: 14px;
    line-height: normal;
`;
export const SpanText = styled.span`
    background: linear-gradient(270deg, #06A0FE -1.08%, #5C49FB 7.99%, #BD14FB 15.29%, #F4284F 23.47%, #F6572D 30.55%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
`;
const DragFile = (props) => {
    return(
        <>
            <DragFileMain>
                <DragFileIcon
                    src={props.Image3}
                    alt="drop file icon"
                />
                <DragFiletext>Drop your resume here or <SpanText>browse</SpanText> </DragFiletext>
            </DragFileMain> 
            {/* } */}
            
        </>
    )
}

export default DragFile;