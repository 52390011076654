import React from "react";
import { Route, Routes } from "react-router-dom";
import { useRecoilValue } from "recoil";
import Form from "./Components/Form/Form";
import Navbar from "./Components/Navbar/Navbar";
import { GlobalStyle } from "./Constant/global";
import LandingPage from "./LandingPage";
import PageNotFound from "./Pages/404/PageNotFound";
import Contact from "./Pages/Contact/Contact";
import Product from "./Pages/Product/Product";
import ServicePage from "./Pages/ServicePage/ServicePage";
import PreviewResume from "./Pages/PreviewResume/PreviewResume"
import { navVisible } from "./Recoil/store";
import Test from "./Test";
import JoinUs from "./Pages/JoinUs/JoinUs";
import AdminLogin from "./Pages/Admin/AdminLogin/AdminLogin";
import AdminDashboard from "./Pages/Admin/AdminDashboard/AdminDashboard";

const App = () => {
  const nav = useRecoilValue(navVisible);
  return (
    <>
      <GlobalStyle />
      <Navbar />
      <Routes >
        <Route exact  path="/adminlogin" element={<AdminLogin />} />
        <Route exact  path="/admindashboard" element={<AdminDashboard />} />
        <Route path="/" element={<LandingPage />} />
        <Route exact  path="/product" element={<Product />} />
        <Route exact  path="/service" element={<ServicePage />} />
        <Route exact  path="/contact" element={<Contact />} />
        <Route exact  path="/joinus" element={<JoinUs />} />
        <Route exact  path="/previewresume" element={<PreviewResume />} />
        <Route path="*" element={<PageNotFound />}/>
      </Routes>
    </>
  );
};

export default App;
