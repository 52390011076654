import React, { useEffect } from "react";
import Home from "./Pages/Home/Home";

import Third from "./Pages/ThirdPage/Third";

import { PageTwo, PageThree, PageFour, PageFive } from "./PageData/PageData";
import CardWrapper from "./Components/CardWrapper/CardWrapper";
import ImageWrapper from "./Components/ImageWrapper/ImageWrapper";
import { VerticalLine } from "./Components/Lines/Line";
import Sixpage from "./Pages/SixPage/Sixpage";
import Footer from "./Pages/Footer/Footer";
import Descriptions from "./Components/Descriptions/Descriptions";
import styled from "styled-components";


export const Contentmain = styled.div`
  @media screen {
    @media (max-width: 992px) {
      display: block;
      position: relative;
      ::after {
          display: block;
          position: absolute;
          content: '';
          top: 0;
          left: 0;
          width: 5px;
          height: 100%;
          background: linear-gradient(180deg,#02F0FF 0%,#2300FF 50%,#02F0FF 100%);
          margin-left: 1.5rem;
        }
        ::before {
          display: block;
          position: absolute;
          content: '';
          top: 0;
          left: 0;
          width: calc( 50% - 1.5rem);
          height: 5px;
          background: linear-gradient(90deg,#02F0FF 0%,#2300FF 100%);;
          margin-left: 1.5rem;
        }
    }
  }
`;


const App = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
    
      <Home />
      <Contentmain>
      
        <Third
          {...PageTwo}
          width="100%"
          children={<CardWrapper />}
          children2={
            <Descriptions background={"radial-gradient(74.36% 74.36% at 50% 50%, rgba(0, 135, 153, 0.40) 0%, rgba(3, 0, 153, 0.00) 100%)"} Heading={PageTwo.Heading} Text={PageTwo.Text} />
          }
        />
        <Third
          {...PageThree}
          width="75%"
          children={<ImageWrapper background={"radial-gradient(74.36% 74.36% at 50% 50%, rgba(0, 194, 255, 0.40) 0%, rgba(3, 0, 153, 0.00) 100%)"} Image={PageThree.Image} />}
          children2={
            <Descriptions background={" radial-gradient(74.36% 74.36% at 50% 50%, rgba(36, 0, 255, 0.25) 27.6%, rgba(253, 243, 3, 0.00) 100%)"} Heading={PageThree.Heading} Text={PageThree.Text} />
          }
        />
        <Third
          {...PageFour}
          width="75%"
          children={<ImageWrapper background={"radial-gradient(74.36% 74.36% at 50% 50%, #D42D5D 0%, rgba(3, 0, 153, 0.00) 100%)"} Image={PageFour.Image} />}
          children2={
            <Descriptions background={"radial-gradient(48.22% 48.22% at 49.97% 49.99%, rgba(189, 0, 255, 0.23) 0%, rgba(208, 0, 0, 0.00) 100%)"} Heading={PageFour.Heading} Text={PageFour.Text} />
          }
        />
        <Third
          {...PageFive}
          width="80%"
          children={<ImageWrapper Image={PageFive.Image} />}
          children2={
            <Descriptions background={"radial-gradient(74.36% 74.36% at 50% 50%, rgba(0, 153, 80, 0.40) 0%, rgba(3, 0, 153, 0.00) 100%)"} Heading={PageFive.Heading} Text={PageFive.Text} />
          }
        />
        <Sixpage />

    </Contentmain>
      <Footer MarginLeftSide={"1.5rem"} HorizantalLineColor="#02F0FF"  VerticalLineColor="linear-gradient(360deg, #0FF 0%, #D60080 100%)"/>
    
    </>
  );
};

export default App;
