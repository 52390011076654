import React from "react";
import { useState } from "react";
import styled from "styled-components";
import { auth } from "../../../Firebase/config";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate} from 'react-router-dom';

export const LoginFrom = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
`;
export const FormMain = styled.div`
    box-sizing: border-box;
    padding: 20px;
    width: 25%;
    border-radius: 20px;
    border: 2px solid rgb(199, 204, 255);
    background: linear-gradient(49deg, rgba(217, 217, 217, 0.1) -8.06%, rgba(217, 217, 217, 0) 132.12%);
    backdrop-filter: blur(50px);
    display: flex;
`;
export const Formlabel = styled.div`
    color: #FFF;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;
export const InputField = styled.input`
    width: 100%;
    box-sizing: border-box;
    padding:15px 20px;
    border-radius: 5px;
    border: 2px solid #C7CCFF;
    background: linear-gradient(49deg, rgba(217, 217, 217, 0.10) -8.06%, rgba(217, 217, 217, 0.00) 132.12%);
    backdrop-filter: blur(50px);
    color: #ffffff;
    ::placeholder {
        color: rgba(255, 255, 255, 0.35);
      }
    :focus-visible {
        outline: none;
    }
     :autofill {
        border: 2px solid #C7CCFF;
        background: linear-gradient(49deg, rgba(217, 217, 217, 0.10) -8.06%, rgba(217, 217, 217, 0.00) 132.12%) ;
        -webkit-backdrop-filter: blur(50px);
        backdrop-filter: blur(50px);
        -webkit-background-clip: text;
        -webkit-text-fill-color: #ffffff;
        transition: background 5000s ease-in-out 0s;
        :focus{
            border: 2px solid #C7CCFF;
            background: linear-gradient(49deg, rgba(217, 217, 217, 0.10) -8.06%, rgba(217, 217, 217, 0.00) 132.12%) ;
            -webkit-backdrop-filter: blur(50px);
            backdrop-filter: blur(50px);
            -webkit-background-clip: text;
            -webkit-text-fill-color: #ffffff;
            transition: background 5000s ease-in-out 0s;
        }
       
    }
`;
export const LoginSubmitBtn = styled.button`
width: 100%;
    box-sizing: border-box;
    padding:15px 20px;
    border-radius: 5px;
    border: 2px solid #C7CCFF;
    background: transparent;
    backdrop-filter: blur(50px);
    color: #ffffff;
`;

export const LoginTitle = styled.h3`
    background: linear-gradient(
      89.8deg,
      #49c9ee -2.92%,
      #641ed3 30.06%,
      #ab0bb3 54.64%,
      #d42d5d 79.23%,
      #f27425 102.01%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    background-size: 200%;
    animation: shine 5s linear infinite;

    @keyframes shine {
      to {
        background-position: 200% 0;
      }
    }
`;
const AdminLogin = () =>{
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const navigate = useNavigate();
    const submit = (e) =>{
        e.preventDefault();
        signInWithEmailAndPassword(auth,email,password).then(data => {
            // console.log("data",data)
            localStorage.setItem('token',JSON.stringify(data.user.accessToken) );    
            // console.log("local",localStorage)
            alert("success");
            navigate('/admindashboard');
            
            setEmail("");
            setPassword("");
        }).catch(err=>{
            alert("Invalid Email and Password");
        })
        
    }
    return(
        <LoginFrom>
            <FormMain>
                <form style={{display: "flex", flexDirection: "column", gap: "20px", width: "100%"}}>
                    <div style={{display: "flex"}}>
                        <LoginTitle>Login </LoginTitle>
                    </div>
                    <div>
                        <Formlabel>USERNAME</Formlabel>
                        <InputField 
                            name="email"
                            type="text" 
                            placeholder="Enter email" 
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        >   
                        </InputField>
                    </div>
                    <div>
                        <Formlabel>PASSWORD</Formlabel>
                        <InputField 
                            name="password"
                            type="password" 
                            placeholder="Enter Password" 
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        >   
                        </InputField>
                    </div>
                    <LoginSubmitBtn onClick={submit} > 
                        Submit
                    </LoginSubmitBtn>
                </form>
            </FormMain>
        </LoginFrom>
    )
}

export default AdminLogin;