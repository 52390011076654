import React, { useEffect } from "react";
import Home from "./Home/Home";
import Third from "../ThirdPage/Third";
import { PageFour, PageThree, PageTwo } from "../../PageData/ProductPage";
import ImageWrapper from "../../Components/ImageWrapper/ImageWrapper";
import ProductDescription from "../../Components/ProductDescription/ProductDescription";
import Footer from "../../Pages/Footer/Footer";
import { Contentmain } from "../../LandingPage";

const Product = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Home />
      <Contentmain>
        <Third
          {...PageTwo}
          width="70%"
          children={<ImageWrapper Image={PageTwo.Image} />}
          children2={<ProductDescription background={"radial-gradient(74.36% 74.36% at 50% 50%, rgba(0, 135, 153, 0.40) 0%, rgba(3, 0, 153, 0.00) 100%)"} {...PageTwo} />}
        />
        <Third
          {...PageThree}
          width="70%"
          children={<ImageWrapper Image={PageThree.Image} />}
          children2={<ProductDescription background={"radial-gradient(74.36% 74.36% at 50% 50%, rgba(36, 0, 255, 0.25) 27.6%, rgba(253, 243, 3, 0.00) 100%)"} {...PageThree} />}
        />
        <Third
          {...PageFour}
          width="70%"
          children={<ImageWrapper Image={PageFour.Image} />}
          children2={<ProductDescription background={"radial-gradient(48.22% 48.22% at 49.97% 49.99%, rgba(189, 0, 255, 0.23) 0%, rgba(208, 0, 0, 0.00) 100%)"} {...PageFour} />}
        />
      </Contentmain>
      <Footer MarginLeftSide={"1.5rem"} HorizantalLineColor="#02F0FF"   VerticalLineColor="linear-gradient(360deg, #0FF 0%, #D60080 100%)"/>
    </>
  );
};

export default Product;
