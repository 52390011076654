import React from "react";
import { useEffect, useState } from "react";
import { useNavigate} from 'react-router-dom';
import styled from "styled-components";
import { db } from "../../../Firebase/config";
import { collection ,getDocs } from "firebase/firestore"; 
import randomColor from "randomcolor";

export const Container = styled.div`
      padding: 190px 100px;
`;
export const DashboarMian = styled.div`
      display: grid;
      grid-template-columns: 25% 1fr;
      gap: 30px;
      @media screen {
        @media (max-width: 992px) {
            grid-template-columns: 1fr;
        }
    }
`;
export const ContentBlock = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 100%;
    overflow-y: scroll;
    -ms-overflow-style: none; 
    scrollbar-width: none;
    ::-webkit-scrollbar { 
        display: none;  
    }
`;
export const ContentWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;    
    width: 100%;
    box-sizing: border-box;
    padding: 15px 20px;
    border-radius: 5px;
    border: 2px solid rgb(199, 204, 255);
    background: linear-gradient(49deg, rgba(217, 217, 217, 0.1) -8.06%, rgba(217, 217, 217, 0) 132.12%);
    backdrop-filter: blur(50px);
    color: rgb(255, 255, 255);
`;
export const LogoutBtn = styled.div`
    display: flex;
    justify-content:center ;
    align-items: center;    
    width: 100%;
    box-sizing: border-box;
    padding: 15px 20px;
    border-radius: 5px;
    border: 2px solid rgb(199, 204, 255);
    background: linear-gradient(49deg, rgba(217, 217, 217, 0.1) -8.06%, rgba(217, 217, 217, 0) 132.12%);
    backdrop-filter: blur(50px);
    color: rgb(255, 255, 255);
`;
export const ContentWrapperInner = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`;
export const Spandata = styled.span`
    font-size: 18px;
    line-height: 1;
    font-weight: 400;
`;
export const PdfViewr = styled.div`
    display: flex;
    justify-content: center;
    height: 70vh;
    padding: 0 50px 50px 50px;
    iframe {
        border-radius: 5px;
        height: 100%;
        width: 100%;
      }
`;
export const ContentBlockMain = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    height: 80vh;
`;




    
const AdminDashboard = () =>{
    const navigate = useNavigate();
    const [storeValues, setStoreValues] = useState([]);
    const [pdfUrl, setPdfUrl] = useState();

    const fetchPostData = async () => {
        const querySnapshot = await getDocs(collection(db,"Applications"));
        const tempArray =[];
        querySnapshot.forEach((doc) =>{
            tempArray.push(doc.data());
        });
        setStoreValues(tempArray);
    }
    useEffect(() => {
        const localToken = localStorage.getItem('token')
        if (!localToken){
            navigate('/*');
        }
        fetchPostData();
      },[]);
    const onLogout = () =>{
        navigate('/adminlogin');
        localStorage.clear();
    }
    return( 
        <Container>
            <DashboarMian>
                <ContentBlockMain>
                    <ContentBlock>
                    {storeValues.length ? storeValues.map((item) => (
                    
                        <ContentWrapper 
                            onClick={(e) => {
                            e.preventDefault();
                            setPdfUrl(item.ResumeUrl);
                        }}
                        >
                            <div style={{display: "flex", gap: "10px"}}>
                                <div 
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    background: "red",
                                    borderRadius: "50%",
                                    padding: "5px",
                                    height: "30px",
                                    width: "30px",
                                    backgroundColor: randomColor({
                                        luminosity: 'random',
                                        hue: 'random',
                                    }),
                                }}
                                >
                                    {item.Name.toUpperCase().slice(0, 1)}
                                </div>
                                <ContentWrapperInner>
                                    <Spandata>{item.Name}</Spandata>
                                    <Spandata>{item.post}</Spandata>
                                </ContentWrapperInner>
                            </div>
                                <Spandata>{item.mobile}</Spandata>
                        
                        </ContentWrapper>  
                    )):
                    <h4 style={{ color: "#fff" }}>No Records Found</h4>}
                    
                    </ContentBlock>
                    <LogoutBtn onClick={onLogout}>
                        Logout
                    </LogoutBtn>
                </ContentBlockMain>
                <PdfViewr>
                    {pdfUrl ? 
                        <iframe src={pdfUrl} />
                        :
                        <h3 style={{ color: "#fff" }}>No File Found</h3>
                    }
                </PdfViewr>
            </DashboarMian>
        </Container>
    )
}
export default AdminDashboard;