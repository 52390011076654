import { ImageWrapper } from "../ThirdPage/Third";
import styled from "styled-components";
import back from "../../Assets/Group 85.svg";


export const Container = styled.div`
  width: 100%;
  position: relative;
  padding-top: 180px;
  padding-bottom: 100px;
  box-sizing: border-box;
  @media screen {
    @media (max-width: 992px) {
      padding-top: 60px;
    }
    @media (min-width: 992px) {
      margin: 0;
    }
  }
`;
export const Section = styled.div`
  flex-direction: column-reverse;
  display: flex;
  justify-content: center;
  row-gap: 1rem;
  padding-top: 60px;
  align-items: center;
  @media screen {
    @media (max-width: 992px) {
      padding: 50px 100px;
      box-sizing: border-box;
    }
    @media (max-width: 768px) {
      padding: 20px;
    }
    @media (min-width: 992px) {
      padding: 0 100px;
      display: flex;
      justify-content: space-between;
      flex-direction: ${(props) => (props.imgStart ? "row-reverse" : "row")};
      gap: 30px;
    }
  }
`;
export const Left = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex: 0 0 50%;
  @media screen {
    @media (max-width: 992px) {
      flex: 0 0 100%;
    }
  }
`;



const TwoSectionWithoutLine = (props) =>{
    return(
        <>
            <Container>
                <Section imgStart={props.imgStart}>
                <Left>
                    <ImageWrapper width={props.width}>{props.children}</ImageWrapper>
                </Left>
                {props.children2}
                </Section>
            </Container>
        </>
    )
}

export default TwoSectionWithoutLine;