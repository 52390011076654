import React from "react";
import styled from "styled-components";

const Vlin = styled.div`
  width: 5px;
  height: 100%;
  min-height: 50px;
  background: ${(props) => props.color};
  @media screen {
    @media (max-width: 992px) {
      display: none;
    }
  }
`;

export const VerticalLine = (props) => {
  return (
    <>
      <Main
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "row",
          flex: 1,
        }}
      >
        <div
          style={{
            width: "calc(40% - 5px)",
            height: "100%",
          }}
        ></div>
        <Vlin color={props.color}></Vlin> 
        <div
          style={{
            width: "60%",
            height: "100%",
          }}
        ></div>
      </Main>
    </>
  );
};
export const Main = styled.div`
  display: block;
  @media screen {
    @media (max-width: 992px) {
      display: none;
    }
  }
`;

export const Lin = styled.div`
  width: calc(50% + 5px);
  height: 5px;
  background: ${(props) => props.color};
  
`;
export const HorizantalLine = (props) => {
  if (props.length === "full") {
    return (
      <>
        <Container
          style={{
            padding: "0 5rem",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              // flexDirection: "row-reverse",
            }}
          >
            <div
               style={{
                width: "calc(20% - 5px)",
              }}
            >

            </div>
            <Lin color={props.color}></Lin>
            {/* <div
              style={{
                width: "29%",
              }}
            ></div> */}
          </div>
          {/* <div
            style={{
              width: "30%",
            }}
          ></div> */}
        </Container>
      </>
    );
  } else if (props.length === "half") {
    return (
      <>
        <Container
          // style={{
          //   padding: "0 5rem",
          // }}
        >
          <div
            style={{
              width: "50%",
              display: "flex",
              justifyContent:" flex-end",
              // flexDirection: "row-reverse",
            }}
          >
            <div
            style={{divwidth40}}
          ></div>
            <Lin2 color={props.color}></Lin2>
            {/* <div
              style={{
                width: "35%",
              }}
            ></div> */}
          </div>
          {/* <div
            style={{
              width: "50%",
            }}
          ></div> */}
        </Container>
      </>
    );
  }
};
const divwidth40 = {
    width: "calc(40% - 5px)",
    display: "flex",
  '@media (max-width: 992px)': {
    width: "0%",
  },
};
export const Container = styled.div`
  display: block;
  padding: 0 5rem;
  @media screen {
    @media (max-width: 992px) {
      padding: 0 1.5rem;
      display: none;
    }
    @media (max-width: 768px) {
      display: none;
    }
  }
`;
const Lin2 = styled.div`
  height: 5px;
  width: calc(60% + 5px);
  background: ${(props) => props.color};
   @media screen {
    @media (max-width: 992px) {
       width: 100%;
       display: none;
    }
    @media (max-width: 768px) {
    }
  }
`;
