
import styled from "styled-components";
import { useState } from 'react';
import { Document, Page } from 'react-pdf';

// Import the styles
// import '@react-pdf-viewer/core/lib/styles/index.css';

export const ResumePreviewMain = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 100%;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    background: #fff;
    @media screen {
        @media (max-width: 768px) {

        }
    }
`;
export const ModalCloseBtnMain = styled.div`
    display: flex;
     justify-content: end;
    // max-width: 1350px;
     width: 100%;
     margin: auto; 
     padding: 0 20px 0px 0px;
    box-sizing: border-box;
    position: sticky;
    top: 0;
    right: 0;
    z-index: 999999;
`;
export const ModalCloseBtn = styled.div`
    color: #fff;
    font-size: 15px;
    font-weight: 600;
    line-height: normal;
    width: 20px;
    height: 20px;
    border: 1px solid #00000055;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    background: #00000033
`;
export const DocumentMain = styled.div`
    margin-top: 30px;
`;


 const ResumePreviewModel = (props) => {
    const { file, closeModal} = props;
    const [numPages, setNumPages] = useState();
    function onDocumentLoadSuccess({ numPages }) {
      setNumPages(numPages);
    }
    // const closeupload = (event) =>{
    //     event.preventDefault()
    //     setFile(null);
       
    // }
    return(
        
        <ResumePreviewMain>
            <ModalCloseBtnMain>
                <ModalCloseBtn onClick={closeModal}>X</ModalCloseBtn>
            </ModalCloseBtnMain>
            <DocumentMain>
                <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
                    {Array.apply(null, Array(numPages))
                        .map((x, i)=>i+1)
                        .map(page => <Page pageNumber={page}  renderTextLayer={false} renderAnnotationLayer={false} />)} 
                </Document>
            </DocumentMain>
          
        </ResumePreviewMain>
    )
}

export default ResumePreviewModel;