
const PreviewResume = (props) =>{

    return(
        <>
          <div
            style={{
                border: '1px solid rgba(0, 0, 0, 0.3)',
                height: '100%',
            }}
        >
         
        </div>
        </>
    )
} 

export default PreviewResume;