import React from "react";
import styled from "styled-components";
import back1 from "../../Assets/Servicepage/card1.svg"

const Card = (props) => {
  // console.log("image", props.iconSize)
  return (
    <>
      <Container background={props.background} >
        <Wrapper>
          <Icon>
            {props.iconSize == 'small' ? <IconImg src={props.icon} alt="icon" /> : <LargeIconImg src={props.icon} alt="icon" />}
           
          </Icon>
          <Title>
            <p>{props.title}</p>
          </Title>
          <Subtitle>
            <p>{props.subtitle}</p>
          </Subtitle>
        </Wrapper>
      </Container>
    </>
  );
};
export default Card;

export const IconImg = styled.img`
  width: 80px;
  height: 80px;
  @media screen {
    @media (max-width: 992px) {
      width: 50px;
      height: 50px;
    }
  }
`
export const LargeIconImg = styled.img`
  max-width: 100%;
  @media screen {
    @media (max-width: 992px) {
      height: 100px;
    }
  }
`


const Container = styled.div`
  // padding: 1rem;
  position: relative;
  ::after{
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    border-radius: 10px;
    background-image: url("${(props) => props.background}");
    background-repeat: no-repeat;
    background-position: bottom left;
   width: 100%;
   height:100%;
   z-index: -1;
  }
  
  @media screen {
    @media (max-width: 992px) {
      flex: 0 0 48%;
    }
    @media (max-width: 768px) {
      flex: 0 0 100%;
    }
  }
 
`;
const Wrapper = styled.div`
  margin-bottom: 0;
  max-width: 400px;
  background: linear-gradient(
    25.52deg,
    rgba(27, 0, 105, 0.2) -7.65%,
    rgba(27, 0, 105, 0) 104.52%
  );
  box-shadow: inset 0px 0px 40px 1px rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(2px);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  height: 100%;
  @media screen {
    @media (max-width: 992px) {
      max-width: unset;
    }
    @media (max-width: 768px) {
      
    }
  }
`;
const Icon = styled.div`
  display: flex;
  // padding: 30px 40px;
  @media screen {
    @media (max-width: 992px) {
      // padding: 10px 20px;
    }
    @media (max-width: 768px) {
      
    }
  }
`;
const Title = styled.div`
  padding: 0 2rem;
  @media screen {
    @media (max-width: 1250px) {
      padding: 0 1rem;
    }
    @media (max-width: 768px) {
     
    }
  }
  p {
    margin: 0;
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    line-height: 156.69%;
    background: linear-gradient(
      89.8deg,
      #49c9ee,
      #641ed3,
      #ab0bb3,
      #d42d5d,
      #f27425,
      #d42d5d,
      #ab0bb3,
      #641ed3,
      #49c9ee
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    background-size: 200%;
    text-fill-color: transparent;
    animation: shine 5s linear infinite;
    @media screen {
      @media (max-width: 1250px) {
        font-size: 16px;
        line-height: 1.2;
      }
      @media (max-width: 768px) {
       
      }
    }
  }
`;
const Subtitle = styled.div`
  padding: 0 2rem 1rem 2rem;
  width: auto;
  @media screen {
    @media (max-width: 1250px) {
      padding: 0.5rem 1rem 1rem 1rem;
    }
    @media (max-width: 768px) {
     
    }
  }
  p {
    margin: 0;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.5);
    @media screen {
      @media (max-width: 1250px) {
        font-size: 12px;
        line-height: 1.5;
      }
      @media (max-width: 768px) {
       
      }
    }
  }
`;
