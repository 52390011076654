import Back from "../Assets/Icons/Group 81.png";

export const PageTwo = {
  imgStart: "imgStart",
  // Heading: "What We Provide?",
  HeadlineColor:
    "linear-gradient(270deg, #4DB3EB -26.32%, #5B5BDD 0.73%, #8017C7 29.47%, #B211A5 61.59%, #DA3A53 97.93%, #ED682F 135.96%)",
  length: "half",
  color1: "linear-gradient(180deg, #03F0FF 0%, #00F0FF 100%)",
  color2: "linear-gradient(180deg, #00F0FF 0%, #FFF500 100%)",
  HorizantalLineColor: "linear-gradient(90deg, #02F0FF 0%, #2300FF 100%)",
};

export const PageThree = {
  Image: Back,
  Heading: "What We Provide?",
  length: "full",
  color1: "linear-gradient(180deg, #FFF500 0%, #2400FF 100%)",
  color2: "linear-gradient(180deg, #2400FF 0%, #FF0000 100%)",
  HorizantalLineColor: "linear-gradient(90deg, #F2F50D 0%, #F2F50D 100%)",
  Text:
    "Our software engineering services include requirements analysis and design, development, testing, maintenance, and support. We use cutting-edge technology and industry-standard methodologies to ensure that our services are reliable and secure. Moreover, our team members are well-versed in the latest software engineering trends and technologies, allowing us to deliver the most up-to-date solutions.",
};

export const PageFour = {
  // imgStart: "imgStart",
  Heading: (
    <h3
      style={{
        textAlign: "center",
        background:
          "linear-gradient(89.8deg, #49c9ee, #641ed3, #ab0bb3, #d42d5d, #f27425, #d42d5d, #ab0bb3, #641ed3, #49c9ee)",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        backgroundClip: "text",
        textFillColor: "transparent",
        backgroundSize: "200%",
        animation: "shine 5s linear infinite",
      }}
    >
      Boost Your Business
    </h3>
  ),
  HeadlineColor:
    "linear-gradient(270deg, #4DB3EB , #5B5BDD 0.73%, #8017C7 29.47%, #B211A5 61.59%, #DA3A53 97.93%, #ED682F 135.96%)",
  length: "full",
  color1: "linear-gradient(180deg, #FF0000 0%, #9206FF 100%)",
  color2: "linear-gradient(180deg, #00FFFF 0%, #D60080 100%)",
  HorizantalLineColor: "linear-gradient(180deg, #F90107 0%, #FF0000 100%);",
};
