import Back from "../Assets/Creative Solution.png";
import Team from "../Assets/Team.png";
import Mission from "../Assets/SecondPage/PowerofCode.svg";

export const PageTwo = {
  Image: Back,
  imgStart: "imgStart",
  Heading: "What We Do?",
  length: "half",
  color1: "linear-gradient(180deg, #03F0FF 0%, #00F0FF 100%)",
  color2: "linear-gradient(180deg, #00F0FF 0%, #FFF500 100%)",

  HorizantalLineColor: "linear-gradient(90deg, #02F0FF 0%, #2300FF 100%)",
  Text:
    "Devetree is a team of best software engineers in the industry. They have the vision, skills, and expertise to develop cutting-edge software solutions to meet the needs of our clients. Their level of dedication and commitment to their work is unparalleled and they are always striving to stay ahead of the curve with the latest technology. ",
};
export const PageThree = {
  Image: Back,
  Heading: "What We Provide?",
  imgStart: "",
  full: "true",
  length: "full",
  HorizantalLineColor: "#F2F50D",
  color1: "linear-gradient(180deg, #FFF500 0%, #2400FF 100%);",
  color2: "linear-gradient(180deg, #2400FF 0%, #FF0000 100%);",

  Text:
    "Our software engineering services include requirements analysis and design, development, testing, maintenance, and support. We use cutting-edge technology and industry-standard methodologies to ensure that our services are reliable and secure. Moreover, our team members are well-versed in the latest software engineering trends and technologies, allowing us to deliver the most up-to-date solutions.",
};
export const PageFour = {
  Image: Mission,
  Heading: "What is Our Mission?",
  imgStart: "imgStart",
  full: "true",
  length: "full",
  HorizantalLineColor: " linear-gradient(180deg, #FF0000 0%, #F90107 100%);",
  color1: " linear-gradient(0deg, #9206FF 0%, #FF0000 100%);",
  color2: " linear-gradient(180deg, #9206FF 0%, #2400FF 65.62%);",

  Text:
    "Our mission is to provide the best applications and software solutions that meet the needs and goals of our users. We strive to provide products that are easy to use, cost-effective, and reliable. Our team of experienced software engineers and developers work hard to produce the highest quality applications and services, tailored to fit the individual needs of our customers.",
};
export const PageFive = {
  Image: Team,
  Heading: "Who We Are?",
  imgStart: "",
  full: "true",
  length: "full",
  HorizantalLineColor: " linear-gradient(180deg, #1A2F7B 0%, #3301FF 100%);",
  color1: " linear-gradient(180deg, #2402FF 0%, #49C8EE 100%);",
  color2: " linear-gradient(180deg, #49C8EE 0%, #00FC65 100%);",

  Text:
    "A powerful team is like a well-oiled machine – each part is necessary for the whole to function. Together, they can accomplish amazing things, from scaling a mountain to creating a business empire. Each team member brings a unique set of skills and strengths to the table that combine to form a powerful force for good.",
};
