import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
// ========================= Icons =========================//
import LinkedIn from "../../Assets/Icons/LinkedIn 2.svg";
import Instagram from "../../Assets/Icons/Instagram.svg";
import Googlemap from "../../Assets/Icons/Google Maps Old.svg";
import Github from "../../Assets/Icons/GitHub.svg";
import Email from "../../Assets/Icons/Secured Letter.svg";
import Phone from "../../Assets/Icons/Phone.svg";
import Location from "../../Assets/Icons/Location.svg";
import back from "../../Assets/Ellipse 19.svg";
import back1 from "../../Assets/footer1.svg";
// ========================= Icons =========================//

const Footer = (props) => {
  return (
    
    <>
      <Container>
      {props.VerticalLineColor ? 
      <FooterVerticalLine>
        <div style={{width: "20%"}}></div>
          <FooterVerticalLineStyle VerticalLineColor={props.VerticalLineColor} />
        </FooterVerticalLine> : ''}
      
        <Line>
          <HorizantalLineFull  MarginLeftSide={props.MarginLeftSide} HorizantalLineColor={props.HorizantalLineColor}/>
        </Line>
        <Wrapper>
          <Section>
              <Left>
                <InfoWrapper>
                  <h3>Devetree</h3>
                  <p>
                    The team of creative and passionate designers and developers
                  </p>
                  <Navlinks>
                    <Link to="/">Home</Link>
                    <Link to="/product">Products</Link>
                    <Link to="/service">Service</Link>
                    <Link to="/contact">Contact</Link>
                    <Link to="/joinus">Join Us</Link>
                  </Navlinks>
                  <SocialIcons>
                    <IconWrapper>
                      <Icon>
                        <a style={{display: 'flex', alignItems: 'center',}} href="https://in.linkedin.com/company/devetree-tachnolabs" target="_blank" ><img src={LinkedIn} alt="LinkedIn" /></a>
                      </Icon>
                    </IconWrapper>

                    <IconWrapper>
                      <Icon>
                        <a style={{display: 'flex', alignItems: 'center',}} href="https://instagram.com/devetreetechnolab?igshid=MzMyNGUyNmU2YQ==" target="_blank" > <img src={Instagram} alt="insta" /> </a>
                      </Icon>
                    </IconWrapper>

                    <IconWrapper>
                      <Icon>
                        <a style={{display: 'flex', alignItems: 'center',}} href=" https://goo.gl/maps/PaxtPWho8oMecwrbA" target="_blank" ><img src={Googlemap} alt="Googlemap" /> </a>
                      </Icon>
                    </IconWrapper>
                    <IconWrapper>
                      <Icon>
                        <a style={{display: 'flex', alignItems: 'center',}} href="https://github.com/DeveTreea" target="_blank" ><img src={Github} alt="Github" /> </a>
                      </Icon>
                    </IconWrapper>
                  </SocialIcons>
                  {/* <p>© {new Date().getFullYear()} Devetree. All rights reserved.</p> */}
                </InfoWrapper>
              </Left>
              <Right>
                <InfoWrapper>
                  <Box>
                    <ContactDetails>
                      <IconWrapper>
                        <Icon>
                          <img src={Email} alt="email" />
                        </Icon>
                      </IconWrapper>
                      <a href="mailto:Support@devetree.com">
                        Support@devetree.com
                      </a>
                    </ContactDetails>
                    <ContactDetails>
                      <IconWrapper>
                        <Icon>
                          <img src={Phone} alt="Phone" />
                        </Icon>
                      </IconWrapper>
                      <a href="tel:+918733896867">+918733896867</a>
                    </ContactDetails>
                    <ContactDetails>
                      <IconWrapper>
                        <Icon>
                          <img src={Location} alt="Location" />
                        </Icon>
                      </IconWrapper>
                      <a target="_blank" href="https://goo.gl/maps/PaxtPWho8oMecwrbA">
                        302, MARVELLA SHOPPERS, Lal Bahadur Shastri Rd, Bardoli,
                        Gujarat 394601
                      </a>
                    </ContactDetails>
                  </Box>
                </InfoWrapper>
              </Right>
            
          </Section>
          <CopyRightText>© {new Date().getFullYear()} Devetree. All rights reserved.</CopyRightText>
        </Wrapper>
      </Container>
    </>
  );
};

export default Footer;

export const Container = styled.div`
  color: white;
  position: relative;
  ::after{
    position: absolute;
    content: '';
    top: 10%;
    left: 0;
    width: 30%;
    height: 50%;
    border-radius: 375px;
    background: radial-gradient(74.36% 74.36% at 50% 50%, rgba(0, 194, 255, 0.40) 0%, rgba(3, 0, 153, 0.00) 100%);
    filter: blur(100px);  
    background-repeat: no-repeat;
    background-position: top left;
    z-index: -1;
  }
  ::before{
    position: absolute;
    content: '';
    bottom: 0;
    right: 0;
    width: 30%;
    height: 50%;
    border-radius: 375px;
    background: radial-gradient(74.36% 74.36% at 50% 50%, rgba(36, 0, 255, 0.25) 27.6%, rgba(253, 243, 3, 0.00) 100%);
    filter: blur(100px);  
    background-repeat: no-repeat;
    background-position: bottom right;
    z-index: -1;
  }
 
`;
export const FooterVerticalLine = styled.div`
  display: flex;
  padding: 0 5rem;
  @media screen {
    @media (max-width: 992px) {
      display: none;
    }
  }
`;
export const FooterVerticalLineStyle = styled.div`
    height: 200px;
    width: 5px;
    background: ${(props) => props.VerticalLineColor};
  }
`;
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
export const Section = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  column-gap: 1rem;
  width: 100%;
  @media screen {
    @media (max-width: 992px) {
      flex-direction: column;
    }
  }
`;
export const Left = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  padding: 2rem 2rem;
  width: 50%;
  @media screen {
    @media (max-width: 992px) {
      width: 100%;
      box-sizing: border-box;
      align-items: flex-start;
    }
    @media (max-width: 768px) {
    }
  }
`;
export const CopyRightText = styled.p`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  padding: 0rem 2rem 2rem 2rem;
  width: 50%;
  @media screen {
    @media (max-width: 992px) {
      width: 100%;
      box-sizing: border-box;
      align-items: flex-start;
    }
    @media (max-width: 768px) {
    }
  }
`;
export const Right = styled.div`
  display: flex;
  padding: 2rem 2rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  // background-image: url("${back}");
  // background-repeat: no-repeat;
  // background-position: bottom right;
  // background-size: 100% 107%;
  @media screen {
    @media (max-width: 992px) {
      width: 100%;
      padding-top: 0;
      box-sizing: border-box;
      align-items: flex-start;
    }
    @media (max-width: 768px) {
    }
  }
`;
export const InfoWrapper = styled.div`
  // padding: 2rem 0rem;
  @media screen {
    @media (max-width: 992px) {
        padding: 0rem;
    }
    @media (max-width: 768px) {
    }
  }
  h3 {
    font-size: 1.5rem;
    font-weight: 400;
    font-family: "Pacifico", cursive;
    padding: 0rem 0.5rem;
  }
  p {
    width: 70%;
    padding: 1rem 0.5rem;
    @media screen {
      @media (max-width: 992px) {
        padding: 0.5rem 0.5rem;
      }
      @media (max-width: 768px) {
      }
  }
`;
export const ContactDetails = styled.div`
  display: flex;
  flex-direction: row;

  padding: 1rem 0rem;
   @media screen {
      @media (max-width: 992px) {
       padding: 0.5rem 0;
      }
      @media (max-width: 768px) {
      }
  }
`;
export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  background: linear-gradient(
    25.52deg,
    rgba(27, 0, 105, 0.2) -7.65%,
    rgba(27, 0, 105, 0) 104.52%
  );
  box-shadow: inset 0px 0px 27px rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(2px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 10px;
`;
export const Navlinks = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem 0rem;
  flex-wrap: wrap;
  a {
    padding: 1rem 2rem 1rem 0.5rem;
    text-decoration: none;
    color: white;
    font-size: 1rem;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
  }
  @media screen {
     @media (max-width: 992px) {
      padding: 0.5rem 0;
    }
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
`;
export const SocialIcons = styled.div`
  padding: 1rem 0rem;
  display: flex;
  flex-direction: row;
  align-items: center;
   @media screen {
     @media (max-width: 992px) {
      padding: 0.5rem 0;
    }
    @media (max-width: 768px) {
    }
  }
`;
export const Line = styled.div``;
export const HorizantalLineFull = styled.div`
  width: 100%;
  height: 5px;
  background-color: ${(props) => props.HorizantalLineColor};
  @media screen {
    @media (max-width: 992px) {
      width: calc(100% - ${(props) => props.MarginLeftSide});
      margin-left:  ${(props) => props.MarginLeftSide};
   }
   @media (max-width: 768px) {
   }
 }
`;

export const IconWrapper = styled.div`
  padding: 0rem 0.5rem;
`;

export const Box = styled.div`
 padding: 40px;
    border-radius: 10px;
    background: linear-gradient(26deg, rgba(27, 0, 105, 0.45) -30.39%, rgba(27, 0, 105, 0.00) 120.8%);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.72);
    backdrop-filter: blur(2px);
  a {
    color: white;
  } /* padding: 4rem;
  background: linear-gradient(
    25.7deg,
    rgba(27, 0, 105, 0.45) -30.39%,
    rgba(27, 0, 105, 0) 120.8%
  );
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.72));
  backdrop-filter: blur(2px);
  border-radius: 10px; */
  @media screen {
    @media (max-width: 992px) {
       padding: 20px;
    }
  }
`;
