import img1 from "../Assets/Icons/Product/app1.png";
import img2 from "../Assets/Icons/Product/app2.png";
import img3 from "../Assets/Icons/Product/app3.png";
import icon1 from "../Assets/Icons/Product/1.svg";
import icon2 from "../Assets/Icons/Product/2.svg";
import icon3 from "../Assets/Icons/Product/3.svg";
import icon4 from "../Assets/Icons/Product/4.svg";
import icon5 from "../Assets/Icons/Product/5.svg";
import icon6 from "../Assets/Icons/Product/6.svg";
import icon7 from "../Assets/Icons/Product/7.svg";
import icon8 from "../Assets/Icons/Product/8.svg";
import icon9 from "../Assets/Icons/Product/9.svg";

export const PageTwo = {
  Image: img1,
  imgStart: "imgStart",
  Heading: "Keep your food fresh",
  HeadlineColor:
    "linear-gradient(270deg, #4DB3EB -26.32%, #5B5BDD 0.73%, #8017C7 29.47%, #B211A5 61.59%, #DA3A53 97.93%, #ED682F 135.96%)",
  icon1: icon1,
  icon2: icon2,
  icon3: icon3,
  titleText1: "Track food Health",
  titleText2: "Track food Health",
  titleText3: "Track food Health",
  briefText1: "Best application to track your food items and their health.",
  briefText2:
    " We provide you spot on notification remind you that you food condition and track food package.",
  briefText3:
    "Every items in freeze is associated with qr code so use can easily identify the expiry date of any food.",
  length: "half",
  color1: "linear-gradient(180deg, #03F0FF 0%, #00F0FF 100%)",
  color2: "linear-gradient(180deg, #00F0FF 0%, #FFF500 100%)",

  HorizantalLineColor: "linear-gradient(90deg, #02F0FF 0%, #2300FF 100%)",
};
export const PageThree = {
  Image: img2,
  imgStart: "",
  Heading: "Keep your car clean every day",
  HeadlineColor:
    "linear-gradient(270deg, #4DB3EB -26.32%, #5B5BDD 0.73%, #8017C7 29.47%, #B211A5 61.59%, #DA3A53 97.93%, #ED682F 135.96%)",
  icon1: icon4,
  icon2: icon5,
  icon3: icon6,
  titleText1: "Daily car wash",
  titleText2: "Track your daily car report",
  titleText3: "Easy and secure payment",
  briefText1: "We clean your car daily at a minimal cost.",
  briefText2:
    " Keep a record of your car's daily cleaning status.",
  briefText3:
    "Enable convenient and safe payment methods .",
  length: "full",
  color1: "linear-gradient(180deg, #FFF500 0%, #2400FF 100%)",
  color2: "linear-gradient(180deg, #2400FF 0%, #FF0000 100%)",

  HorizantalLineColor: "#F2F50D",
};
export const PageFour = {
  Image: img3,
  imgStart: "imgStart",
  Heading: "Stick with your diet",
  HeadlineColor:
    "linear-gradient(270deg, #4DB3EB -26.32%, #5B5BDD 0.73%, #8017C7 29.47%, #B211A5 61.59%, #DA3A53 97.93%, #ED682F 135.96%)",
  icon1: icon7,
  icon2: icon8,
  icon3: icon9,
  titleText1: "Diet Food On Doorstep",
  titleText2: "On time Delivery",
  titleText3: "Track food Health",
  briefText1: "Receive diet-friendly meals conveniently delivered to your door.",
  briefText2:
    "Reliable and timely delivery straight to your doorstep, via our application.",
  briefText3:
    "Manage and monitor your food-related health aspects.",
  length: "full",
  color1: "linear-gradient(180deg, #FF0000 0%, #9206FF 100%)",
  // color2: "linear-gradient(180deg, #00FFFF 0%, #D60080 100%)",

  HorizantalLineColor: " linear-gradient(180deg, #F90107 0%, #FF0000 100%);",
};
