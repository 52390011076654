import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
html{
    font-size: 20px;
    scroll-behavior: smooth;
}
  body {
  
    
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: #050216;
    position: relative;
    ::after{
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      border-radius: 684px;
      background: radial-gradient(102.35% 102.35% at 50% -2.35%, rgba(86, 0, 153, 0.40) 0%, rgba(86, 0, 153, 0.00) 100%);
      filter: blur(40px);
      background-repeat: no-repeat;
      background-position: bottom left;
      width: 100%;
      height: 100%;
      max-width: 400px;
      z-index: 1;
      max-height: 200px;
      @media screen and (max-width: 768px) {
          display: none;
      }
    }


  }

  h1,h2 ,h3 ,p,a{
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;

  }

  p{
    font-size: clamp(0.8rem, 2vw, 1rem);
    
    font-style: normal;
    font-weight: 500;

    line-height: 1.5rem;
  }
  a{
    font-size: clamp(0.8rem, 2vw, 1rem);
    text-decoration: none;
    font-style: normal;
    font-weight: 500;
    

    line-height: 1.5rem;
  }
 h1{
  font-size: clamp(3rem, 10vw, 5rem);

 }
 h2{
  font-size: clamp(1.1rem, 6vw, 3.25rem);
     font-weight: 500;
       font-style: normal;
       color: #fff;

   
   @media screen and (min-width: 800px) {
     

       line-height: 98px;

       
    }
 }
  h3{
    font-size: clamp(1.5rem, 2vw, 1.75rem);
    font-style: normal;
    font-weight: 600;
  }

  .react-pdf__Page__canvas{
    height: auto !important;
    max-width: 1000px ;
    width: 100% !important;
    margin: auto;
    @media screen and (max-width: 1200px) {
      max-width: 992px ;
   }
   @media screen and (max-width: 992px) {
        max-width: 650px ;
    }
    @media screen and (max-width: 768px) {
      max-width: 350px ;
  }
  }
  .sixpagecaurasol{
    .rec-item-wrapper{
      height: 100%;
    }
  }
`;
