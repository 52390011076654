
import { initializeApp } from "firebase/app";
import {getStorage} from "firebase/storage"
import { getFirestore} from "firebase/firestore"
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCnZ4UM3As7m9qMhy5Nr_-9nT1itmKxZew",
  authDomain: "devetreenewweb.firebaseapp.com",
  projectId: "devetreenewweb",
  storageBucket: "devetreenewweb.appspot.com",
  messagingSenderId: "809539342731",
  appId: "1:809539342731:web:526e1bb91aab9f9244df8e"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const fileDB = getStorage(app);
export const db = getFirestore(app);
export const auth = getAuth(app);
