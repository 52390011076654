
import Footer from "../Footer/Footer";
import TwoSectionWithoutLine from "../TwoSectionWithoutLine/TwoSectionWithoutLine";
import { PageTwo,OurTeamData,VacancyData } from "../../PageData/JoinUsPage";
import ImageWrapper from "../../Components/ImageWrapper/ImageWrapper";
import JoinUsFrom from "../../Components/JoinUsFrom/JoinUsFrom";
import { Suspense, lazy } from "react";
import JoinUsContact from "../../Components/JoinUsContact/JoinUsContact";
import Carosule from "../../Components/Carosule/Carosule";
import * as React from 'react';
// import OurTeam from "../../Components/OurTeam/OurTeam";

const OurTeam = React.lazy(() => import("../../Components/OurTeam/OurTeam"))

const JoinUs = () =>{
    return(
        <>
            <TwoSectionWithoutLine
                {...PageTwo}
                width="100%"
                children={<ImageWrapper Image={PageTwo.Image} />}
                children2={<JoinUsFrom {...PageTwo} />}
            />
            {/* <Carosule CaurasolData={VacancyData} /> */}
            <Suspense>
                {/* {console.log("Parent")} */}
                <OurTeam OurTeam={OurTeamData} />
            </Suspense>
            <Footer MarginLeftSide={"0rem"} HorizantalLineColor="#02F0FF"  />
        </>
    )
}

export default JoinUs;