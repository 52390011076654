import React from "react";
import styled from "styled-components";

export const ImgWrapper = styled.div`
  padding: 5rem 0rem;
  position: relative;
  ::after{
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    border-radius: 527px;
    filter: blur(150px);
    background-image: ${(props) => props.background};
    background-repeat: no-repeat;
    background-position: bottom left;
    width: 100%;
    height:100%;
    z-index: -1;
  }
  @media screen and (max-width: 992px) {
    padding: 2rem 0rem;
  }
  @media screen and (max-width: 768px) {
    padding: 1.5rem 0rem 2.5rem 0rem;
  }
`;
export const ProductImage = styled.img`
  width: 100%;
  max-width: 800px;
  object-fit: contain;
  max-height: 500px;
  height: 100%;
  @media screen {
    @media (max-width: 768px) {
      max-width: 300px;
    }
  }
`

const ImageWrapper = (props) => {
  return (
    <>
      <ImgWrapper background={props.background}>
        <ProductImage
          src={props.Image}
          alt="back"
        />
      </ImgWrapper>
    </>
  );
};

export default ImageWrapper;
