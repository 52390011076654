import styled from "styled-components";
import Blur from "../../Assets/Glow/2.svg";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: auto;
  padding: 0rem 1rem;
  position: relative;
  ::after{
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    border-radius: 527px;
    filter: blur(150px);
    background-image: ${(props) => props.background};
    background-repeat: no-repeat;
    background-position: bottom left;
    width: 100%;
    height:100%;
    z-index: -1;
  }
  @media (max-width: 992px) {
     padding: 0rem 0rem;
}
`;
export const Title = styled.div`
  display: flex;
`;
export const Brief = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    color: rgba(255, 255, 255, 0.7);
  }
`;
