
import React, { useEffect } from "react";
import Home from "./Home";
import Processor from "../../Assets/Icons/Processor.svg";
import styled from "styled-components";
import Third from "../ThirdPage/Third";
import { HorizantalLine, VerticalLine } from "../../Components/Lines/Line";
import { PageFour, PageTwo ,PageThree } from "../../PageData/ServicePage";
import ImageWrapper from "../../Components/ImageWrapper/ImageWrapper";
import Descriptions from "../../Components/Descriptions/Descriptions";
import Card from "../../Components/Card/Card";
import Footer from "../Footer/Footer";
import { Line } from "./Home";
import { Contentmain } from "../../LandingPage";
import LinesFullSection from "../../Components/LinesFullSection/LinesFullSection";
import BoostBusiness from "../../Assets/Icons/boostBusiness.png";
import card1 from "../../Assets/Servicepage/card1.svg";
import card2 from "../../Assets/Servicepage/card2.svg";
import card3 from "../../Assets/Servicepage/card3.svg";
import card4 from "../../Assets/Servicepage/card4.svg";
import card5 from "../../Assets/Servicepage/card5.svg";
import card6 from "../../Assets/Servicepage/card6.svg";
import cardicon1 from "../../Assets/Servicepage/cardicon1.svg"
import cardicon2 from "../../Assets/Servicepage/cardicon2.svg"
import cardicon3 from "../../Assets/Servicepage/cardicon3.svg"
import cardicon4 from "../../Assets/Servicepage/cardicon4.svg"
import cardicon5 from "../../Assets/Servicepage/cardicon5.svg"
import cardicon6 from "../../Assets/Servicepage/cardicon6.svg"


export const Line2 = styled.div`
  height: 61px;
  width: 5px;
  background: linear-gradient(180deg, #00F0FF 0%, #FFF500 100%);
  @media (max-width: 992px) {
    display: none;
  }
`;


const Container = styled.div`
  padding: 25px 100px;
  @media screen {
    @media (max-width: 768px) {
      padding: 0 1.5rem 1.5rem 3rem;
    }
  }

`;
export const BoostBusinessImg = styled.img`
  padding: 57px 0;
  @media screen {
    @media (max-width: 768px) {
      padding: 30px 0;
    }
  }
`
export const BoostBusinessTitle = styled.div`
  padding-bottom: 15px;
  @media screen {
    @media (max-width: 768px) {

    }
  }
`
export const BoostBusinessImgBorder = styled.div`
  width: 100%;
  height: 1px;
  background: radial-gradient(37.1% 300.06% at 50% 50%, #ED0000 0%, #F27425 31.25%, #A100BB 70.83%, rgba(27, 0, 105, 0.13) 99.99%);
`
export const BoostBusinessImgMain = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  ::after{
    position: absolute;
    content: '';
    top: 30%;
    right: 5%;
    width: 30%;
    height: 60%;
    border-radius: 375px;
    background: radial-gradient(74.36% 74.36% at 50% 50%, #D42D5D 0%, rgba(3, 0, 153, 0.00) 100%);
    filter: blur(100px);
    z-index: -1;
  }
`
export const BoostBusinessMain = styled.div`
  display: flex;
 flex-direction: column;
  justifyContent: center;
  position: relative;
  ::after{
    position: absolute;
    content: '';
    top: -5%;
    left: -5%;
    width: 41%;
    height: 90%;
    border-radius: 688px;
    background: radial-gradient(48.22% 48.22% at 49.97% 49.99%, rgba(189, 0, 255, 0.23) 0%, rgba(208, 0, 0, 0.00) 100%);
    filter: blur(50px);
    z-index: -1;
  }
`
export const ServiceCardMain = styled.div`
  display: grid;
  grid-template-columns: repeat(3,1fr);
  justify-content: center;
  gap: 1rem;
  padding: 1.5rem ;
  width: fit-content;
    margin: auto;
  @media screen {
    @media (max-width: 992px) {
      padding: 1.5rem 1.5rem 1.5rem 3rem;
      grid-template-columns: repeat(2,1fr);
    }
    @media (max-width: 768px) {
      grid-template-columns: repeat(1,1fr);
    }
  }
`



const ServicePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Home />
      <Contentmain>
       <LinesFullSection length="half" verticallinecolor="linear-gradient(180deg, #02F0FF 0%, #2300FF 100%)"  horizontallinecolor="linear-gradient(90deg, #02F0FF 0%, #2300FF 100%)" />
      {/* <Third
        {...PageTwo}
        width="70%"
         children={<ImageWrapper Image={PageTwo.Image} />}
        children2={
          <Descriptions Heading={PageTwo.Heading} Text={PageTwo.Text} />
        }   
      /> */}
      <ServiceCardMain>
        
        <Card
          title="Product Architecture "
          subtitle=" A type of consulting or professional service that helps organizations design and develop the product architecture for their products. This may involve conducting market research, gathering requirements, creating prototypes, and developing detailed design specifications. "
          icon={cardicon1}
          background = {card1}
        />
        <Card
          title="Apps Development "
          subtitle="At Devetree, we specialize in providing top-quality mobile application development services to businesses and organizations of all sizes. Our team of experienced developers is skilled in a variety of programming languages and frameworks. "
          icon={cardicon2}
          background = {card2}
        />
        <Card
          title="Web Development "
          subtitle=" Web development is the process of building and maintaining websites. A web development service helps organizations design, build, and maintain their websites. This may involve creating custom websites or working with pre-existing platforms."
          icon={cardicon3}
          background = {card3}
        />
        <Card
          title="Digital Marketing  "
          subtitle="Digital marketing is the use of digital channels to promote or market products and services to consumers. A digital marketing service helps businesses plan and execute their digital marketing strategies."
          icon={cardicon4}
          background = {card4}
        />
        <Card
          title="UI/UX Designing "
          subtitle="UI/UX stands for user interface and user experience. A UI/UX service is a type of service that helps organizations design and optimize the user interface and user experience of their products and services, with the goal of making them easy, intuitive, and enjoyable to use"
          icon={cardicon5}
          background = {card5}
        />
        <Card
          title="Cloud Managment Services "
          subtitle="Cloud management is the process of managing and optimizing the use of cloud computing resources. A cloud management service is a type of service that helps organizations plan, deploy, and manage their use of cloud computing resources."
          icon={cardicon6}
          background = {card6}
        />
      </ServiceCardMain>
      <div
        style={{
          display: "flex",
          // height: "100%",
          // width: "100%",
          // flexDirection: "row",
          // flex: "1 1 0%",
          padding:" 0 5rem",
        }}
      >
        <div
        style={{
          display: "flex",
          height: "100%",
          width: "100%",
        }}
      >
        <div 
          style={{
            // width: "35.8%",
            width: "calc( 20% - 5px)",
          }}
        ></div>
          <Line2></Line2>
          </div>
      </div>
        <Third
        {...PageThree}
        width="70%"
        children={<ImageWrapper Image={PageThree.Image} />}
        children2={
          <Descriptions background={"radial-gradient(74.36% 74.36% at 50% 50%, rgba(36, 0, 255, 0.25) 27.6%, rgba(253, 243, 3, 0.00) 100%)"} Heading={PageThree.Heading} Text={PageThree.Text} />
        }
      />
      {/* <Third
        {...PageFour}
        width="70%"
        // children={<ImageWrapper Image={PageFour.Image} />}
        children2={
          <Descriptions Heading={PageFour.Heading} Text={PageFour.Text} />
        }
      /> */}
         <LinesFullSection length="full" verticallinecolor="linear-gradient(180deg, #F00 0%, #9206FF 100%)"  horizontallinecolor="linear-gradient(180deg, #F00 0%, #F90107 100%)" />
          <Container>
              <BoostBusinessMain>
                  <BoostBusinessTitle>
                      <Descriptions style={{paddingBotttom: "25px"}} Heading={PageFour.Heading} Text={PageFour.Text} />
                  </BoostBusinessTitle>
                  <BoostBusinessImgBorder />
                  <BoostBusinessImgMain>
                    <BoostBusinessImg style={{width: "100%",maxWidth: "1500px"}} src={BoostBusiness} alt="BoostBusinessImg" />
                  </BoostBusinessImgMain>
                  <BoostBusinessImgBorder />
                  {/* <VerticalLine color="linear-gradient(180deg, #F00 0%, #9206FF 100%)" /> */}
              </BoostBusinessMain>
          </Container>
      </Contentmain>
      <Footer MarginLeftSide={"1.5rem"} HorizantalLineColor="#02F0FF"  VerticalLineColor="linear-gradient(360deg, #0FF 0%, #D60080 100%)" />
    </>
  );
};



export default ServicePage;

